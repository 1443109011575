export default class ClientTransaction {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.clientTransaction = {
      transaction_value: '',
      old_credit: '',
      credit_expire_date: '',
      transaction_type: '',
      source_type: '',
      source_id: '',
      direction: '',
      valid_after: '',
      user_wallet_id: '',
      userWallet: { name: '' }
    }
  }

  fillData (data) {
    if (data) {
      this.clientTransaction.transaction_value = data.transaction_value ? data.transaction_value : ''
      this.clientTransaction.old_credit = data.old_credit ? data.old_credit : ''
      this.clientTransaction.credit_expire_date = data.credit_expire_date ? data.credit_expire_date : ''
      this.clientTransaction.transaction_type = data.transaction_type ? data.transaction_type : ''
      this.clientTransaction.source_type = data.source_type ? data.source_type : ''
      this.clientTransaction.source_id = data.source_id ? data.source_id : ''
      this.clientTransaction.direction = data.direction ? data.direction : ''
      this.clientTransaction.valid_after = data.valid_after ? data.valid_after : ''
      this.clientTransaction.user_wallet_id = data.user_wallet_id ? data.user_wallet_id : ''
      this.clientTransaction.userWallet.name = data.userWallet.name ? data.userWallet.name : ''
    } else {
      this.setInitialValue()
    }
  }
}
